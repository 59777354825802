<app-header></app-header>

<main>
  <!-- product-banner-info -->
  <section class="product-banner-info" style="background: #212121;">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div>
            <span class="product-banner-header-5">THE DOMAIN NAME</span>
            <h1 class="product-banner-text-5">{{domain}}</h1>
            <div class="product-banner-text-6">is available for acquisition!</div>
            <!-- <p>{{bio}}</p> -->
             
            <hr style="width: 400px; display: block; margin: 20px 0;" />
            <div>
              <a href="/purchase/{{brand_id}}?type=purchase">
                <button class="tpinkbtn-5">Purchase<span style="pointer-events: none;">{{price |
                    currency}}</span></button></a>
            </div>
            <!-- <span class="product-banner-text-7">(*The listed price shown is non negotiable. For more information visit our FAQ page*)</span> -->
          
            <div>
              <div class="lower-buttons">
                <!-- <a href="/purchase/{{brand_id}}?type=offer"><button class="lower-buttons-2">Make
                    Offer <input type="text" name="offer" id="txt-offer" [(ngModel)]="offer" /></button></a> -->
                <span class="lower-buttons-2" (click)="sendOffer()">Make
                  Offer <input type="number" name="offer" id="txt-offer" [(ngModel)]="offer"
                    (keyup)="sendOffer2($event)" (click)="$event.target.select(); $event.stopPropagation()" /></span>
                <a href="/purchase/{{brand_id}}?type=lease-to-own"><button class="lower-buttons-2">Lease-to-own from
                    <span>{{ leaseToOwnPrice | currency}}</span></button></a>
              </div>
              <div class="purchase-options-comments">
                We offer flexible payment options!
                Purchase the domain outright or lease-to-own over time.
              </div>
              <hr style="width: 400px; display: block; margin: 20px 0;" />
              <div class="comments-box-2">
               <div class="comment-icon">
                 <img class="ellipse" src="assets/images/ellipse-comments.svg">
                 <img class="union" src="assets/images/union-comments.svg">
               </div>
               <h3 class="comments-box-3">Comments from the owner:</h3>
               <span class="comments-box-4">{{comments}}</span>
             </div>
             
            </div>
          </div>
        </div>
        <div class="col-md-6">

          <div class="product-simple-card">


            <div class="product-simple-box">
              <div class="bigimage videofile hide" id="videofile" *ngFor="let data of videofile" style="height: 100%;">
                <video autoplay #videoRef (canplay)="playVideo(videoRef)"
                  (playing)="setVideoStatus(videoRef, 'playing')" (pause)="setVideoStatus(videoRef, 'pause')" id="click"
                  width="100%" height="100%" controls playsinline>
                  <source src="{{data.name}}" type='video/mp4'>
                  Your browser does not support the audio element.
                </video>
                <span class="playicon" (click)="playVideo(videoRef)"></span>
              </div>

              <div class="bigimage videolink hide" *ngIf="video != ''">
                <iframe [src]='video' height="400" width="500">
                </iframe>
              </div>

              <div class="bigimage sliderimage hide" *ngIf="images.length > 0">
                <img class="sliderimagesrc" src="{{images[0].name}}">
              </div>


              <div class="bigimage audiofile hide" *ngFor="let data of audio">
                <audio controls id="audio-play">
                  <source src="{{data.name}}" type="audio/ogg">
                  <source src="{{data.name}}" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
              </div>

              <div class="bigimage certificate hide" *ngFor="let data of certificate">
                <img src="{{data.name}}">
              </div>
            </div>



            <div class="product-simple-list" style="overflow-x: auto;">

              <div class="product-simple-list-itme" (click)="mediaClick('videofile')" *ngFor="let data of videofile">
                <img src="assets/images/video-black.png">
              </div>

              <div class="product-simple-list-itme" (click)="mediaClick('videolink')" *ngIf="video != ''">
                <img src="assets/images/video-black.png">
              </div>

              <div class="product-simple-list-itme" (click)="mediaClick('sliderimage', data)"
                *ngFor="let data of images">
                <!-- <img src="{{data.thumbnails_name}}"> -->
                <img src="assets/images/brandlogo.png">
              </div>

              <div class="product-simple-list-itme" (click)="mediaClick('audiofile')" *ngFor="let data of audio">
                <img src="assets/images/audio-black.png">
              </div>

              <div class="product-simple-list-itme" (click)="mediaClick('certificate')"
                *ngFor="let data of certificate">
                <img src="{{data.thumbnails_name}}">
              </div>

            </div>
          </div>

          <div class="banner-links-bottom">
            <!-- <a class="product-banner-links-5" href="javascript:;" *ngFor="let data of keyword_list">{{data.keyword}}</a> -->
            <!-- If you're interested in this domain please make an offer above, or feel free to <a href="/contact-us"
              target="_blank">Contact Us</a> -->
            Interested in {{ domain }}? Make an Offer, or <a href="/contact-us" target="_blank">Contact Us</a> today to
            explore options!
          </div>

        </div>
         
      </div>
    </div>
  </section>
  <!-- end product-banner-info -->
  <section class="product-brand">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="product-list-names">
            <h3 class="list-text">Similar Names</h3>
            <h1 class="list-header">Other Names You May Like</h1>
            <span class="list-text-2"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <carousel [cellsToShow]="4" [loop]="true" [height]="240" [arrowsOutside]='true' [arrowsTheme]="'dark'">
          <div class="col-md-3 col-sm-4 col-xs-12 col-lg-3 carousel-cell" *ngFor="let data of BrandList">
            <a (click)="industrynavigate(data.brand_id)">
              <div class="product-brand-card-detail-2">
                <div class="product-brand-card-img">
                  <img *ngIf="data.images.thumbnails_name !== ''" src="{{data.images.thumbnails_name}}" alt="images">
                  <img *ngIf="data.images.name === ''" src="images/maira.png" alt="images">
                  <a routerLink="/branddetail/{{ data.brand_id }}" title="{{data.domain}}"
                    class="site g-site candela">{{data.domain}}<em class="data-price">{{data.price | currency}}</em></a>
                </div>
              </div>
            </a>
          </div>
        </carousel>
      </div>
    </div>
  </section>

  <!-- product-business-purchase -->
  <section class="product-business-purchase">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>What do you get with your purchase?</h2>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12 col-lg-4">
              <div class="product-business-card">
                <div class="product-business-card-img">
                  <img src="assets/images/domain-black.png">
                </div>
                <div class="product-business-card-text">
                  <h3>Premium Domain Name</h3>
                  <p>Exceptionally high quality domain name. This domain name is specially curated for its brand
                    viability and memorability.
                    It's great for big businessess looking to grow mega brands.</p>
                </div>
              </div>
            </div>
            <div class="ol-md-4 col-sm-4 col-xs-12 col-lg-4">
              <div class="product-business-card">
                <div class="product-business-card-img">
                  <img src="assets/images/open-box-black.png">
                </div>
                <div class="product-business-card-text">
                  <h3>High quality logo</h3>
                  <p>
                    You get a high quality logo package designed by professionals. This package includes the logo,
                    vectors,
                    .eps file, photoshop .psd file, illustrator .ai file, black/white versions and more. You get all
                    copyrights in the logo.
                    Many people will re-brand and create new branding material, but feel free to use the logo we
                    created!
                  </p>
                </div>
              </div>
            </div>
            <div class="ol-md-4 col-sm-4 col-xs-12 col-lg-4">
              <div class="product-business-card">
                <div class="product-business-card-img">
                  <img src="assets/images/certificate-black.png">
                </div>
                <div class="product-business-card-text">
                  <h3>Voice and animation files</h3>
                  <p>Some domain names also include voice and animation files.
                    Get in contact if you'd like us to create any additional animation, voice, or design materials for
                    you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- end product-business -->

  <!-- product-faq -->
  <section class="product-faq">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title">
            <span>FAQ's</span>
            <h2>Frequenty Asked Questions</h2>
          </div>
        </div>
        <div class="frequent-cuestions col-md-12">
          <div>
            <div class="frequent-text"></div>
            <div class="main-questions-box">
              <h4 class="main-questions">Why buy a business name from brands.com?
                <h5 class="main-answers">
                  The best brand names are ones that are easy to remember. That is our focus. Easy to remember domain
                  names that are also very brandable. Names like this market the most efficiently and can effectively
                  scale. On Brands.com you'll find many 4-letter .com domains such as 'ahba.com'. You'll also find many
                  one word .com domains such as 'footage.com'. 4-letter .com domains and one word .com domains make up
                  most of the Brands.com portfolio.
                </h5>
              </h4>
              <h4 class="main-questions">Why do you only sell .com domains?
                <h5 class="main-answers">
                  Because .com is baked into our language and psychology. Everybody understands that when we say "Dot
                  Com" we're talking about a website address. If you use a different TLD as a business name such a '.io'
                  for example. Not everyone is going to understand what you mean. But everybody knows Dot Com. It is the
                  most understood TLD in our language. The whole point of Brands.com is high quality brand names that
                  market the most efficiently. That's why we decided to only sell .com domains on Brands.com
                </h5>
              </h4>
              <h4 class="main-questions">What do I get when I purchase a domain name from Brands.com?
                <h5 class="main-answers">
                  You get the domain name itself of course which will be transferred to your registrar. You also get a
                  business and marketing package. The contents of the package depends on the domain name. All domains
                  include high quality logo files. Many domains also include marketing videos and animations. You keep
                  the copyrights.
                </h5>
              </h4>
              <h4 class="main-questions">Can I negotiate the price for a domain name I want?
                <h5 class="main-answers">
                  All prices are professionally set at what we believe are fair market prices. Therefore the listed
                  prices are firm. However we may be able to give some value off the listed price for some domains.
                </h5>
              </h4>
              <h4 class="main-questions">How do I purchase a domain name from brands.com?
                <h5 class="main-answers">
                  You can purchase domain names directly on our website. We use Escrow.com to facilitate our domain name
                  transactions (fees paid). Escrow.com is an accredited Escrow service. The Brands.com domain name
                  portfolio is also listed on major domain name marketplaces.
                </h5>
              </h4>
            </div>
            <h4 routerLink="/faq" class="domain-sales">See all frequently asked questions</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end product-faq -->

  <!-- product-Brand -->

  <!-- end product-banner-info -->
</main>
<app-footer></app-footer>
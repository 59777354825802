'use strict';
import * as Helper from '../globals/helper';
// server config
export function validateRegister(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };
    // Get values
    // Required
    if (Helper.isEmpty(Obj.fullName)) {
        errros['fullName'] = 'Full Name is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.userName)) {
        errros['userName'] = 'User Name is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (!Helper.isEmpty(Obj.phone) && !Helper.isValidMobile(Obj.phone)) {
        errros['phone'] = 'Phone number is invalid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.password)) {
        errros['password'] = 'Password is required'
        returnValidator.isValid = false;
    } else if (Obj.password.length < 8) {
        errros['password'] = 'Password must be at least 8 characters long including 1 uppercase letter';
        returnValidator.isValid = false;
    } else if (Obj.password !== Obj.confirm_password) {
        errros['confirm_password'] = 'Password and confirm password should match';
        returnValidator.isValid = false;
    }

    if (!Obj.terms) {
        errros['terms'] = 'Please accept terms & conditions'
        returnValidator.isValid = false;
    }

    // if (Helper.isEmpty(Obj.longitude)) {
    //     errros['password'] = 'Please enter valid address.';
    //     returnValidator.isValid = false;
    // }

    // if (Helper.isEmpty(Obj.latitude)) {
    //     errros['password'] = 'Please enter valid address.';
    //     returnValidator.isValid = false;
    // }

    // if (Helper.isEmpty(Obj.address)) {
    //     errros['password'] = 'Address is required';
    //     returnValidator.isValid = false;
    // }

    if (!Helper.validPassword(Obj.password)) {
        errros['password'] = 'Password must be at least 8 characters long including 1 uppercase letter';
        returnValidator.isValid = false;
    }
    else if (Obj.password.length < 8) {
        errros['password'] = 'Password must be at least 8 characters long including 1 uppercase letter';
        returnValidator.isValid = false;
    }
    // if (Obj.password !== Obj.confirmPassword) {
    //     errros['confirmPassword'] = 'Password and confirm password should match';
    //     returnValidator.isValid = false;
    // }

    return returnValidator;
};


export function validateClubRegister(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.firstName)) {
        errros['firstName'] = 'First Name is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.lastName)) {
        errros['lastName'] = 'Last Name is required ';
        returnValidator.isValid = false;
    }
    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.club)) {
        errros['club'] = 'Organization is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.password)) {
        errros['password'] = 'Password is required';
        returnValidator.isValid = false;
    }

    else if (!Helper.validPassword(Obj.password)) {
        errros['password'] = 'Password must be at least 8 characters long including 1 uppercase letter';
        returnValidator.isValid = false;
    }
    else if (Obj.password.length < 8) {
        errros['password'] = 'Password must be at least 8 characters long including 1 uppercase letter';
        returnValidator.isValid = false;
    }

    return returnValidator;
};

export function validatePayment(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };
    // Get values
    // Required
    if (Helper.isEmpty(Obj.fullName)) {
        errros['fullName'] = 'Full Name is required ';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.address)) {
        errros['address'] = 'Address is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.city)) {
        errros['city'] = 'Please enter valid city.';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.state)) {
        errros['state'] = 'State is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.postal_code)) {
        errros['postal_code'] = 'Postal code is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.country)) {
        errros['country'] = 'Country is required';
        returnValidator.isValid = false;
    }


    if (!Obj.terms) {
        errros['terms'] = 'Please accept terms & conditions'
        returnValidator.isValid = false;
    }

    return returnValidator;
};


export function validateContact(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.name)) {
        errros['name'] = 'Name is required';
        returnValidator.isValid = false;
    }


    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.message)) {
        errros['message'] = 'message is required';
        returnValidator.isValid = false;
    }


    return returnValidator;
};

export function validateServiceContact(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.name)) {
        errros['name'] = 'Name is required';
        returnValidator.isValid = false;
    }


    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.service)) {
        errros['service'] = 'Please choose one service';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.about)) {
        errros['about'] = 'This field is required';
        returnValidator.isValid = false;
    }


    return returnValidator;
};


export function validatePartnerContact(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.name)) {
        errros['name'] = 'Name is required';
        returnValidator.isValid = false;
    }


    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }


    if (Helper.isEmpty(Obj.message)) {
        errros['message'] = 'This field is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.domains)) {
        errros['domains'] = 'This field is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.website)) {
        errros['website'] = 'This field is required';
        returnValidator.isValid = false;
    }


    return returnValidator;
};


export function validatePurchase(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.name)) {
        errros['name'] = 'Name is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.message)) {
        errros['message'] = 'Message is required';
        returnValidator.isValid = false;
    }


    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.domain)) {
        errros['domain'] = 'Invalid domain';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.price) || !Helper.isNumber(Obj.price) || Obj.price < 1) {
        errros['price'] = 'Invalid price';
        returnValidator.isValid = false;
    }


    return returnValidator;
};


export function validateGeneralDomain(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.general_domain)) {
        errros['general_domain'] = 'Domain name is required.';
        returnValidator.isValid = false;
    } else if (!Obj.general_domain.match(/^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]\.(?:com)$/i)) {
        errros['general_domain'] = 'Only .com domain extensions are accepted.'
        returnValidator.isValid = false;
    }

    return returnValidator;
};


export function validateBusinessDomain(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };


    if (Helper.isEmpty(Obj.business_domain)) {
        errros['business_domain'] = 'Business domain is required.';
        returnValidator.isValid = false;
    }
    else if (!Obj.business_domain.match(/^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]\.(?:com)$/i)) {
        errros['business_domain'] = 'Only .com domain extensions are accepted'
        returnValidator.isValid = false;
    }

    return returnValidator;
};



export function validateCertificateForm(Obj) {

    var errros = {};
    var returnValidator = {
        errors: errros,
        isValid: true
    };

    if (Helper.isEmpty(Obj.first_name)) {
        errros['first_name'] = 'First name is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.last_name)) {
        errros['last_name'] = 'Last name is required';
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.email)) {
        errros['email'] = 'Email is required '
        returnValidator.isValid = false;
    } else if (!Helper.isValidEmail(Obj.email)) {
        errros['email'] = 'Email is not valid '
        returnValidator.isValid = false;
    }

    if (Helper.isEmpty(Obj.business_name)) {
        errros['business_name'] = 'Business name is required';
        returnValidator.isValid = false;
    }


    // if (Helper.isEmpty(Obj.business_name)) {
    //     errros['business_name'] = 'Business Name is required';
    //     returnValidator.isValid = false;
    // }



    // if (Helper.isEmpty(Obj.general_domain)) {
    //     errros['general_domain'] = 'Domain name is required.';
    //     returnValidator.isValid = false;
    // } else if (!Obj.general_domain.match(/^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]\.(?:com)$/i)) {
    //     errros['general_domain'] = 'Only .com domain extensions are accepted.'
    //     returnValidator.isValid = false;
    // }





    return returnValidator;
};

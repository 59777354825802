import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { BrandService } from '../../services/brand.service';

// import * as $ from 'jquery'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public ActivecatgeoryList = [];
  public BrandFilterList: any = [];
  timer
  public search_keyword: any ;
  public sort_by: any ;  
  public category: any ;
  public search_type: any ;
  public min_price: any = 0;
  public max_price: any = 0;
  public min_length: any = 0;
  public max_length: any = 0;
  public brand_type: any = 'all';
  public filterSortBy: any;

  public brandcount = 0;
  public brandsearchtext;
  public pageSizes = 15;
  pageOfItems: Array<any>;
  public filter_class: any = false;

  constructor(public brandService: BrandService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.search_keyword = params['keyword'];    
        this.searchapply();
      }
    )
   }

  ngOnInit() {
    this.brandService.getActiveCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        this.ActivecatgeoryList = res.data;
        
      }
    });
    $('.seraech-field .form-group .buttonlinks a').on('click', function () {
      $(this).addClass('active').parent().siblings().children().removeClass('active');
    });
    $("header .search-grp a").click(function () {
      $(".seraech-field").addClass("open-filer");
    });
    $(".seraech-field .form-group a.closebtn ").click(function () {
      $(".seraech-field").removeClass("open-filer");
    });    
    
  }


  onChangePage(pageOfItems: Array<any>) {
    var scrollingElement = document.scrollingElement || document.documentElement;
    scrollingElement.scrollTop = 2;
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  clearall(){

    this.search_keyword = '';
    this.sort_by = '';
    this.category = '';
    this.search_type = '';
    this.min_price = 0;
    this.max_price = 0;
    this.min_length = 0;
    this.max_length = 0; 
    this.searchapply()
  }

  cancel(){
    this.search_keyword = '';
    this.sort_by = '';
    this.category = '';
    this.search_type = '';
    this.min_price = 0;
    this.max_price = 0;
    this.min_length = 0;
    this.max_length = 0;
    this.searchapply()
  }
  
  openHide() {
    this.filter_class = !this.filter_class;
  }

  searchapply(){
    $(".seraech-field").removeClass("open-filer");
    this.brandsearchtext = this.search_keyword

    var obj = {
      search_keyword : this.search_keyword,
       sort_by : this.sort_by,
       category : this.category,
       search_type : this.search_type,
       min_price : this.min_price,
       max_price : this.max_price,
       min_length : this.min_length,
       max_length : this.max_length,
       brand_type: this.brand_type
    }
    this.brandService.searchApply(obj).subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        if (res.data.length > 0){          
          if (this.sort_by){
            if (this.sort_by == 'price_low_to_high'){
              this.filterSortBy = "Price Low to High"
            } else if (this.sort_by == 'price_high_to_low') {
              this.filterSortBy = "Price High to Low"
            } else if (this.sort_by == 'first_letter') {
              this.filterSortBy = "First Letter Alphabetically"
            } else if (this.sort_by == 'date_added') {
              this.filterSortBy = "Date Added"
            } else if (this.sort_by == 'relevance') {
              this.filterSortBy = "Relevance"
            }else {
              this.filterSortBy = this.sort_by
            }
            
          }          
          this.BrandFilterList = [];
          // this.timer = setTimeout(() => {}, 4000);
          this.BrandFilterList = res.data.sort((a, b) => b.price - a.price);
          this.brandcount = res.data.length;
        }
      }else{
          this.filterSortBy = '';
          this.BrandFilterList = [];
          this.brandcount = res.data.length;
      }
    });
  }

}

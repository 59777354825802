import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPayPalModule } from 'ngx-paypal';
//import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { JwPaginationModule } from 'jw-angular-pagination';
// import bn-ng-idle service

//import { CarouselModule } from 'ngx-owl-carousel-o';

import { AuthenticationService } from './services/authentication.service';
// import { CommonService } from './services/common.service';
// import { UserService } from './services/user.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomeComponent } from './component/home/home.component';
import { HeaderComponent } from './component/header/header.component';
import { SearchComponent } from './component/search/search.component';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ActivationAccountComponent } from './component/activation-account/activation-account.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { BrandDetailComponent } from './component/brand-detail/brand-detail.component';
import { PurchaseComponent } from './component/purchase/purchase.component';
import * as $ from 'jquery';
import { NiceSelectModule } from "ng-nice-select";
import { FirstnameComponent } from './component/firstname/firstname.component';
import { IndustrynameComponent } from './component/industryname/industryname.component';
import { FaqComponent } from './component/faq/faq.component';
import { PaymentComponent } from './component/payment/payment.component';
import { LeasetopayComponent } from './component/leasetopay/leasetopay.component';
import { BanktransferComponent } from './component/banktransfer/banktransfer.component';
import { PaymentbanktransferComponent } from './component/paymentbanktransfer/paymentbanktransfer.component';
import { HeaderwithoutcartComponent } from './component/headerwithoutcart/headerwithoutcart.component';
import { CmsPageComponent } from './component/cms-page/cms-page.component';
import { AboutComponent } from './component/about/about.component';
import { MarketingServicesComponent } from './component/marketing-services/marketing-services.component'
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { CertificateMainComponent } from './component/certificate-main/certificate-main.component';
import { CertificateFormComponent } from './component/certificate-form/certificate-form.component';
import { CertificatePaymentComponent } from './component/certificate-payment/certificate-payment.component';
import { CertificateListComponent } from './component/certificate-list/certificate-list.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    HeaderComponent,
    SearchComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ActivationAccountComponent,
    ResetPasswordComponent,
    BrandDetailComponent,
    PurchaseComponent,
    FirstnameComponent,
    IndustrynameComponent,
    FaqComponent,
    PaymentComponent,
    LeasetopayComponent,
    BanktransferComponent,
    PaymentbanktransferComponent,
    HeaderwithoutcartComponent,
    CmsPageComponent,
    AboutComponent,
    MarketingServicesComponent,
    ContactUsComponent,
    CertificateMainComponent,
    CertificateFormComponent,
    CertificatePaymentComponent,
    CertificateListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    //CarouselModule,
    // NgxPaginationModule,
    JwPaginationModule,
    NiceSelectModule,
    NgxPayPalModule,
    ModalModule.forRoot(),
    IvyCarouselModule,
    RecaptchaV3Module
  ],
  providers: [AuthenticationService, AuthGuard, { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeequAkAAAAAGzmssjvdp3Yz0kuIH4hMG-ELs-p" }],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './component/home/home.component';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/register/register.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ActivationAccountComponent } from './component/activation-account/activation-account.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { SearchComponent } from './component/search/search.component';
import { FirstnameComponent } from './component/firstname/firstname.component';
import { IndustrynameComponent } from './component/industryname/industryname.component';
import { FaqComponent } from './component/faq/faq.component';
import { BrandDetailComponent } from './component/brand-detail/brand-detail.component';
import { PurchaseComponent } from './component/purchase/purchase.component';
import { LeasetopayComponent } from './component/leasetopay/leasetopay.component';
import { PaymentComponent } from './component/payment/payment.component';
import { BanktransferComponent } from './component/banktransfer/banktransfer.component';
import { PaymentbanktransferComponent } from './component/paymentbanktransfer/paymentbanktransfer.component';
import { CmsPageComponent } from './component/cms-page/cms-page.component';
import { AboutComponent } from './component/about/about.component';
import { MarketingServicesComponent } from './component/marketing-services/marketing-services.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { CertificateMainComponent } from './component/certificate-main/certificate-main.component';
import { CertificateFormComponent } from './component/certificate-form/certificate-form.component';
import { CertificatePaymentComponent } from './component/certificate-payment/certificate-payment.component';
import { CertificateListComponent } from './component/certificate-list/certificate-list.component';


const routes: Routes = [
  {
    path: '', component: HomeComponent, data: {
      title: 'Brand',
      metaDescription: "Brand",
      metaKeywords: "Brand"
    }
  },
  {
    path: 'login', component: LoginComponent, data: {
      title: 'Login | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'register', component: RegisterComponent, data: {
      title: 'Register | Brand ',
      metaDescription: "Brand",
      metaKeywords: "Brand"
    }
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent, data: {
      title: 'Forgot Password | Brand ',
      metaDescription: "Brand",
      metaKeywords: "Brand"
    }
  },
  {
    path: 'reset-password', component: ResetPasswordComponent, data: {
      title: 'Reset Password | Brand',
      metaDescription: "Brand",
      metaKeywords: "Brand"
    }
  },
  {
    path: 'activation-account', component: ActivationAccountComponent, data: {
      title: 'Activation Account | Brand',
      metaDescription: "Brand",
      metaKeywords: "Brand"
    }
  },
  {
    path: 'search', component: SearchComponent, data: {
      title: 'Search | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'branddetail/:id', component: BrandDetailComponent, data: {
      title: 'Detail | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'purchase/:id', component: PurchaseComponent, data: {
      title: 'Purchase | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'firstname', component: FirstnameComponent, data: {
      title: 'First Name | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'industryname/:cat', component: IndustrynameComponent, data: {
      title: 'Industry Specific Names | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'faq', component: FaqComponent, data: {
      title: 'FAQ | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'payment', component: PaymentComponent, data: {
      title: 'Payment | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'leasetopay', component: LeasetopayComponent, data: {
      title: 'Lease to pay | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'banktransfer', component: BanktransferComponent, data: {
      title: 'Bank transfer | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'paymentbanktransfer', component: PaymentbanktransferComponent, data: {
      title: 'Payment | Brand',
      metaDescription: "Brand",
      metaKeywords: 'Brand'
    }
  },
  {
    path: 'about', component: AboutComponent, data: {
      title: 'View about | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'marketing-services',
    component: MarketingServicesComponent,
    data: {
      title: 'Marketing Services | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'contact-us', component: ContactUsComponent, data: {
      title: 'Contact Us | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'certificate', component: CertificateMainComponent, data: {
      title: 'Certificate | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'certificate-form', component: CertificateFormComponent, data: {
      title: 'Certificate | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'certificate-payment', component: CertificatePaymentComponent, data: {
      title: 'Certificate | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'certificates', component: CertificateListComponent, data: {
      title: 'Certificate | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  {
    path: 'cms-page/:slug', component: CmsPageComponent, data: {
      title: 'View detail | Brand',
      metaDescription: '',
      metaKeywords: ''
    }
  },
  { path: '', component: HomeComponent, outlet: 'home' },
  { path: '**', redirectTo: '/' },
];

const userRoutes: Routes = [
  {
    path: 'user', children: [

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    RouterModule.forRoot(userRoutes, { onSameUrlNavigation: 'reload' }),
    // RouterModule.forRoot(routes ),
    // RouterModule.forRoot(userRoutes ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }


<app-header></app-header>

<main>
  <section class="sign-up-section" style="border-top: none;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <form novalidate="" class="sign-up-form login-width ">
            <div class="sign-up-form-head">
              <h3>{{ title }}</h3>
            </div>
            <div class="sign-up-form-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="text" placeholder="Your Name *" name="name" id="name" required
                        [(ngModel)]="name" class="form-control ">
                      <small class="error" *ngIf="validationErrors.name">{{
                        validationErrors.name}}</small>
                    </div>

                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="email" [(ngModel)]="email" placeholder="Email *" name="email"
                        id="email" required class="form-control">
                      <small class="error" *ngIf="validationErrors.email">{{
                        validationErrors.email}}</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="text" [(ngModel)]="domain" disabled name="domain" id="domain"
                        required class="form-control">
                      <small class="error" *ngIf="validationErrors.domain">{{
                        validationErrors.domain}}</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><input type="number" [(ngModel)]="price" name="price" id="price" required
                        [disabled]="!priceEnabled" class="form-control" min="100" step="1">
                      <small class="error" *ngIf="validationErrors.price">{{
                        validationErrors.price}}</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-group blmd-form">
                    <div class="blmd-line"><textarea rows="6" [(ngModel)]="message" required
                        placeholder="Tell us about why you're interested in this name. Please include as many details as possible so we can vet your inquiry. *"
                        height="150" name="message" class="form-control"></textarea>
                      <small class="error" *ngIf="validationErrors.message">{{
                        validationErrors.message}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="customLoader" style="display: none;"><img src="assets/images/loader.svg" alt=""
                      style="width: 40px;"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button (click)="sendForm()" class="btnsignup">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
<header>
  <div class="container">
    <a routerLink="/" title="Brands" class="logo"><img src="assets/images/logo3.png" alt="brand"></a>
    <nav>
      <ul>
        <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/search']"
            [routerLinkActiveOptions]="{exact:true}" routerLinkActive="is-activity" title="Premium Domain Names">Premium
            Domain Names</a></li>
        <!-- <li><a routerLink="/firstname" title="First Names">First Names</a></li>
        <li><a routerLink="/search" title="Industry Specific Names">Industry Specific Names</a>
          <div class="brand-mega-menu">
            <h4>Browse business names by industry</h4>
            <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12  brand-menu-list">
              <a routerLink="/industryname/{{cat.slug}}" *ngFor="let cat of ActivecatgeoryList"><span>{{cat.category_name}}</span></a>
            </div>
          </div>
        </li> -->
        <li><a [routerLink]="['/about']" routerLinkActive="is-activity" id="certificate-form" title="about">About</a>
        </li>
        <li><a [routerLink]="['/contact-us']" routerLinkActive="is-activity" id="certificate-form"
            title="about">Contact</a></li>
        <li><a [routerLink]="['/marketing-services']" routerLinkActive="is-activity" id="certificate-form"
            title="marketing-services">Marketing Services</a></li>
      </ul>
    </nav>
    <div class="search-grp">
      <form (submit)="search()">
        <input type="text" name="keyword" [(ngModel)]="keyword" class="form-control" placeholder="Search…">
        <a (click)="search()" title="Filter"><img src="assets/images/filter.png" alt="filter"></a>
        <button type="submit" style="display:none">hidden submit</button>
      </form>
      <!-- <input type="text" name="keyword" [(ngModel)]="keyword" class="form-control" placeholder="Search…"> -->
      <!-- <a (click)="search()" title="Filter"><img src="assets/images/filter.png" alt="filter"></a> -->
    </div>
    <!-- <small class="error" *ngIf="validationErrors.keyword">{{ validationErrors.keyword}}</small> -->
    <a href="javascript:;" title="menu" class="menu visible-xs visible-sm brands-msite-menu"><img
        src="assets/images/menu.svg" alt="menu"></a>
    <!-- <div class="car-btn"> -->
    <!-- data-toggle="modal" data-target="#addCart" -->
    <!-- <a href="javascript:;" class="vector-cart" (click)="openCart()" >
      </a>
    </div> -->
    <div class="user-profile" *ngIf="checkLogin">
      <a href="javascript:;" data-placement="bottom" data-toggle="tooltip" title="{{user.fullName}}">
        <span>{{username}}</span>
      </a>
    </div>
  </div>
  <div class="search-grp mobile visible-xs">
    <form (submit)="search()">
      <input type="text" name="keyword" [(ngModel)]="keyword" class="form-control" placeholder="Search…">
      <a (click)="search()" title="Filter"><img src="assets/images/filter.png" alt="filter"></a>
      <button type="submit" style="display:none">hidden submit</button>
    </form>
  </div>
</header>


<ng-template #carttemplate>
  <div class="cart-header">
    <div class="cart-img">
      <img src="assets/images/cart.png">
    </div>
    <h4>Your Cart</h4>
    <button type="button" class="close" (click)="modalRef.hide()" data-dismiss="modal"
      aria-label="Close">&times;</button>
  </div>
  <div class="cart-body">
    <div class="row" *ngIf="CartList && CartList[0] && CartList[0].length > 0">
      <div class="col-md-12">
        <div class="cart-list" *ngFor="let carts of CartList[0]">
          <div class="cart-name">
            <h3><a href="javascript:;">{{carts.domain}}</a></h3>
          </div>
          <div class="cart-value">
            <span>{{carts.price | currency}}</span>
            <span><a (click)="removeItem(carts)"><i class="fa fa-times-circle"></i></a></span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="total">
          <h3>{{CartTotalPrice | currency}}</h3>
        </div>
      </div>
    </div>

    <div>
      <div class="col-md-12" *ngIf="CartList[0] == null || CartList[0].length == 0">
        <div class="empty">
          Cart is empty
        </div>
      </div>

    </div>
  </div>
  <div class="cart-foot">
    <div class="row">
      <div class="" *ngIf="CartList && CartList[0] && CartList[0].length > 0">
        <div class="col-md-6">
          <button (click)="redirectPayment()" class="checkoutbtn">Checkout</button>
        </div>
        <div class="col-md-6">
          <button (click)="modalRef.hide()" class="checkoutbtn">Keep Browsing</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>



<div class="brands-Mobile-Menu">
  <h3><img src="assets/images/logo.png" alt="brand"> <a routerLink="/"><i class="fa fa-times-circle"></i></a></h3>
  <ul class="">
    <li><a [routerLink]="['/search']" [routerLinkActiveOptions]="{exact:true}" [routerLinkActive]="['active']"
        role="tab" ng-class="{active:tab.active}">Premium Domain Names</a></li>
    <li><a [routerLink]="['/about']" [routerLinkActive]="['active']" id="certificate-form"
        title="Certificate Request">About</a></li>
    <li><a [routerLink]="['/contact-us']" [routerLinkActive]="['active']" title="Certificate Registry">Contact</a></li>
  </ul>
</div>
import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand.service';

import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';


import * as $ from 'jquery'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public BrandList: any = [];
  public pageSizes = 8;
  pageOfItems: Array<any>;

  constructor(public brandService: BrandService) { }

  ngOnInit() {

    this.brandService.getActiveBrandList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        let BrandList = res.data;        
        this.BrandList = BrandList.sort((a, b) => b.price - a.price);
      }
    });

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    if (this.pageOfItems && this.pageOfItems.length > 0){
     var pos = $("#pagi-top").offset().top;
      window.scroll({ top: pos, left: 0, behavior: 'auto' })
    }
    this.pageOfItems = pageOfItems;
  }

}
